<!--
 * @Description: 应用管理
 * @Author: 琢磨先生
 * @Date: 2022-05-09 10:55:24
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-12-05 15:19:00
-->
<template>
  <el-card class="box">
    <el-button type="primary" size="small" @click="onEdit()" round icon="plus"
      >新增</el-button
    >
  </el-card>
  <el-card class="box data">
    <el-table :data="list" v-loading="loading" border>
      <el-table-column
        label="应用key"
        prop="app_key"
        width="200"
      ></el-table-column>
      <el-table-column
        label="平台"
        prop="platform_text"
        width="140"
      ></el-table-column>
      <el-table-column
        label="类型"
        prop="type_text"
        width="140"
      ></el-table-column>

      <el-table-column label="名称" prop="name" min-width="200"></el-table-column>
      <el-table-column label="渠道编码" prop="ota_code" width="180"></el-table-column>

      <el-table-column
        label="创建时间"
        prop="create_at"
        width="180"
      ></el-table-column>
      <el-table-column label="操作" width="140" fixed="right">
        <template #default="scope">
          <el-button type="primary" link size="small" @click="onEdit(scope.row)"
            >修改</el-button
          >
          <el-popconfirm title="确定要删除？" @confirm="onDelete(scope.row)">
            <template #reference>
              <el-button type="danger" link size="small" class="text-danger"
                >删除</el-button
              >
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
  <edit-app :item="current" :reload="loadData"></edit-app>
</template>
<script>
import app_api from "@/http/app_api";
import EditApp from "./edit_app.vue";
export default {
  components: {
    EditApp,
  },
  data() {
    return {
      loading: false,
      current: null,
      list: [],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      app_api.get_apps().then((res) => {
        if (res.code == 0) {
          this.list = res.data;
        }
      });
    },
    /**
     * 新增、修改
     */
    onEdit(item) {
      this.current = item ? item : {};
    },
    /**
     * 删除
     * @param {*} item
     */
    onDelete(item) {
      this.$http.get("/admin/v1/app/del?appkey=" + item.app_key).then((res) => {
        if (res.code == 0) {
          this.$message({ type: "success", message: res.msg });
          this.loadData();
        }
      });
    },
  },
};
</script>
