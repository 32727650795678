<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2022-05-10 00:54:52
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-08-27 01:42:21
-->
<template>
  <el-dialog
    v-model="drawer"
    :title="title"
    width="600px"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="类型" prop="type">
        <el-select v-model="form.type" :disabled="form.id > 0">
          <el-option v-for="item in types" :key="item.value" :label="item.text" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="平台">
        <el-select v-model="form.platform" :disabled="form.id > 0" style="width:100px;">
          <el-option v-for="item in enums.platforms" :key="item.value" :label="item.text" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="名称" prop="name">
        <el-col :span="14">
          <el-input v-model="form.name" placeholder="请输入应用名称"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="渠道编码" prop="ota_code">
        <el-col :span="14">
          <el-input v-model="form.ota_code" placeholder></el-input>
        </el-col>
        <div class="help-block">渠道编码必须与民宿管理渠道设置编码一致</div>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="loading" :disabled="loading">确定</el-button>
    </template>
  </el-dialog>
</template>
<script>
import common_api from "@/http/common_api";
export default {
  data() {
    return {
      drawer: false,
      loading: false,
      title: "编辑应用",
      enums:{},
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: "请输入应用名称",
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: "必选",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: ["item", "reload"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.drawer = true;
          this.form = Object.assign({}, this.item);
        }
      },
    },
  },
  created() {
    common_api.get_enums().then((res) => {
      if (res.code == 0) {
        this.enums=res.data;
        this.types = res.data.app_types; 
      }
    });
  },
  methods: {
    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http
            .post("/admin/v1/app/edit", this.form)
            .then((res) => {
              this.loading = false;
              if (res.code == 0) {
                this.drawer = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                if (this.reload) {
                  this.reload();
                }
              }
            })
            .cath(() => {
              this.loading = false;
            });
        }
      });
    },
    /**
     * 取消
     */
    cancelClick() {
      this.drawer = false;
      if (this.reload) {
        this.reload();
      }
    },
  },
};
</script>
