/*
 * @Description:
 * @Author: 琢磨先生
 * @Date: 2022-05-08 16:11:08
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-03-14 10:54:36
 */
import http from "./axios_init.js";

export default {
  /**
   * 获取所有的应用数据
   * @returns
   */
  get_apps() {
    return http.post("/admin/v1/app");
  },

  /**
   * 根据code获取应用
   * @param {*} code 
   * @returns 
   */
  get_by_code(code){
    return http.get("/admin/v1/app/by_code?code="+code);
  },

  /**
   * 获取应用组
   */
  get_group(){
    return http.get("/admin/v1/app/group");
  }
};
